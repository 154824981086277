import React from 'react'
import { Link } from 'gatsby'
import Logo from './Logo'
export default () => (
  <footer className="shadow-inner" style={{ backgroundColor: '#f7f7f7' }}>
    <div class="container mx-auto  px-8">
      <div class="w-full flex flex-col md:flex-row py-6">
        <div class="flex-1 mb-6">
          <a
            class="text-orange-600 no-underline hover:no-underline font-bold text-2xl lg:text-4xl"
            href="/"
          >
            <Logo />
          </a>
        </div>

        <div class="flex-1">
          <p class="uppercase text-orange-sherpa md:mb-6">Links</p>
          <ul class="list-reset mb-6">
          <li class="mt-2 inline-block mr-2 md:block md:mr-0">
              <Link
                to="/"
                class="no-underline hover:underline text-gray-800 hover:text-orange-500"
              >
                Home
              </Link>
            </li>
            <li class="mt-2 inline-block mr-2 md:block md:mr-0">
              <Link
                to="/project"
                class="no-underline hover:underline text-gray-800 hover:text-orange-500"
              >
                Project
              </Link>
            </li>
          </ul>
        </div>
        <div class="flex-1">
          <p class="uppercase text-orange-sherpa md:mb-6">Legal</p>
          <ul class="list-reset mb-6">
            <li class="mt-2 inline-block mr-2 md:block md:mr-0">
              <Link
                to="/terms"
                class="no-underline hover:underline text-gray-800 hover:text-orange-500"
              >
                Terms
              </Link>
            </li>
            <li class="mt-2 inline-block mr-2 md:block md:mr-0">
              <Link
                to="/privacy"
                class="no-underline hover:underline text-gray-800 hover:text-orange-500"
              >
                Privacy
              </Link>
            </li>
          </ul>
        </div>
        <div class="flex-1">
          <p class="uppercase text-orange-sherpa md:mb-6">Social</p>
          <ul class="list-reset mb-6">
            <li class="mt-2 inline-block mr-2 md:block md:mr-0">
              <a
                href="https://www.facebook.com/sherpaindopro/"
                target="_blank"
                rel="noreferrer"
                class="no-underline hover:underline text-gray-800 hover:text-orange-500"
              >
                Facebook
              </a>
            </li>
            <li class="mt-2 inline-block mr-2 md:block md:mr-0">
              <a
                href="https://www.instagram.com/sherpaindoproject/"
                target="_blank"
                rel="noreferrer"
                class="no-underline hover:underline text-gray-800 hover:text-orange-500"
              >
                Instagram
              </a>
            </li>
          </ul>
        </div>
        <div class="flex-1">
          <p class="uppercase text-orange-sherpa md:mb-6">Company</p>
          <ul class="list-reset mb-6">
            <li class="mt-2 inline-block mr-2 md:block md:mr-0">
              <Link
                to="/blog"
                class="no-underline hover:underline text-gray-800 hover:text-orange-500"
              >
                Official Blog
              </Link>
            </li>

            <li class="mt-2 inline-block mr-2 md:block md:mr-0">
              <Link
                to="/contact"
                class="no-underline hover:underline text-gray-800 hover:text-orange-500"
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
)
