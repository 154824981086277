import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Meta from './Meta'
import Footer from './Footer'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons' 

library.add(faWhatsapp); 

export default ({ children, meta, title }) => {
  return (
    <StaticQuery
      query={graphql`
        query IndexLayoutQuery {
          settingsYaml {
            siteTitle
            siteDescription
            socialMediaCard {
              image{
                childImageSharp {
                  fluid(maxWidth: 1600, quality: 98) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          allPosts: allMarkdownRemark(
            filter: { fields: { contentType: { eq: "postCategories" } } }
            sort: { order: DESC, fields: [frontmatter___date] }
          ) {
            edges {
              node {
                fields {
                  slug
                }
                frontmatter {
                  title
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const { siteTitle, socialMediaCard } = data.settingsYaml || {}

        return (
          <Fragment>
            <Helmet
              defaultTitle={siteTitle}
              titleTemplate={`%s | ${siteTitle}`}
            >
              {title}
              {/* Add font link tags here */}
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />
            </Helmet>

            <Meta
              absoluteImageUrl={
                socialMediaCard &&
                socialMediaCard.image &&
                socialMediaCard.image
              }
              {...meta}
              {...data.settingsYaml}
            />

            {children}
            <Footer />
            <button
              href="https://api.whatsapp.com/send?phone=6282216101222&text=Terima+kasih+telah+menghubungi+team+Sherpa+Indo+Project.+Silakan+ketik+info+%F0%9F%98%8A"
              class="livechat"
              target="_blank"
            >
              <a href="https://api.whatsapp.com/send?phone=6282216101222&text=Terima+kasih+telah+menghubungi+team+Sherpa+Indo+Project.+Silakan+ketik+info+%F0%9F%98%8A"
     
              target="_blank"><FontAwesomeIcon className="my-float" icon={['fab', 'whatsapp']} /></a>
              

            </button>
          </Fragment>
        )
      }}
    />
  )
}
